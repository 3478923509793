// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAKJyamZmM7gn0ObEsNqP5mBSqm7wsyqa4",
    authDomain: "poka-studios.firebaseapp.com",
    projectId: "poka-studios",
    storageBucket: "poka-studios.appspot.com",
    messagingSenderId: "270109414544",
    appId: "1:270109414544:web:c4cffb50f6901247bef415",
    measurementId: "G-NBKKQNZCJX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);